import React, { useContext } from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { navigate } from "gatsby";
import AppContext from "../../context/AppContext";
const { themePaths, themePageUrlPaths } = require("../../../paths.js");

const CategoryCard = ({ category }) => {
  const { updateCategoryFilter } = useContext(AppContext);
  const handleClick = () => {
    updateCategoryFilter(category?.name);
    navigate(
      `/${themePaths.blog}${themePageUrlPaths.allBlogs}/${category?.slug}`
    );
  };
  return (
    <div className="mx-auto max-w-md">
      <div
        className="w-full h-56 bg-center bg-cover"
        style={{
          backgroundImage: `url(${process.env.GATSBY_ASSET_URL}${category?.featured_image?.url})`,
        }}
      ></div>
      <button
        onClick={handleClick}
        className="p-4 bg-bgSecondary text-lg text-secondary font-bold flex bg-opacity-60 w-full justify-between"
      >
        <span>{category?.name}</span>
        <span>
          <ArrowRightAltIcon />
        </span>
      </button>
    </div>
  );
};

export default CategoryCard;
