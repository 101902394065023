import React from "react";
import Button from "../atoms/Button";
import ArticleCard from "../molecules/ArticleCard";

const TrendingArticles = ({
  showHeading = true,
  blog_articles,
  darkmode,
  loadMoreArticles,
  hideMoreBtn = false,
}) => {
  return (
    <div>
      {showHeading && (
        <h2 className="uppercase mb-12 text-3xl font-bold text-center">
          Trending <span className="text-primary">Articles</span>
        </h2>
      )}

      <ul className="space-y-12 sm:grid  sm:gap-x-7 sm:gap-y-16 sm:space-y-0 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {blog_articles?.map((article) => (
          <li key={article.id}>
            <ArticleCard article={article} darkmode={darkmode} />
          </li>
        ))}
      </ul>

      {!hideMoreBtn && (
        <div className="flex justify-center mt-10">
          <Button type="secondary" onClick={loadMoreArticles}>
            More articles
          </Button>
        </div>
      )}
    </div>
  );
};

export default TrendingArticles;
