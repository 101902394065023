import * as React from "react";
import Layout from "../../components/Layout";
import TrendingArticles from "../../components/organisms/TrendingArticles";
import PopularCategories from "../../components/organisms/PopularCategories";

import { graphql, Link, navigate } from "gatsby";

import { Helmet } from "react-helmet";
import Container from "@mui/material/Container";
import AuthorBar from "../../components/molecules/AuthorBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AppContext from "../../context/AppContext";
import ParsedHTML from "../../components/organisms/ParsedHTML";

import { useState } from "react";

const { themePaths, themePageUrlPaths } = require("../../../paths.js");

/**
 * Displays a single article for the blog
 */

//If you want to use variables in the GraphQL queries, you have to pass them via context in the createPage function in gatsby-node.js as explained here: https://www.gatsbyjs.org/docs/programmatically-create-pages-from-data/

const Article = ({ data, pageContext }) => {
  const { updateCategoryFilter, sortedArticles, updateSortedArticles } =
    React.useContext(AppContext);

  const trendingArticles = React.useRef();
  const [currentArticle, setCurrentArticle] = React.useState();
  const [error, setError] = useState(false);
  const [navSlugs, setNavSlugs] = React.useState({
    prevSlug: null,
    nextSlug: null,
  });
  const articleSlug = pageContext.slug;
  const articleData = data.allStrapiBlogArticle.nodes;
  const strapiCategories = data.allStrapiCategory.nodes;
  const blocks = pageContext.blocks;

  React.useEffect(() => {
    if (articleData) {
      trendingArticles.current = [...articleData.slice(0, 4)];
    }
  }, [articleData]);

  React.useEffect(() => {
    let articleList = articleData;
    if (sortedArticles.length) {
      articleList = sortedArticles;
    }
    const currentArticleIndex = articleList.findIndex((article) => {
      return article.slug === articleSlug;
    });
    if (currentArticleIndex !== -1) {
      setCurrentArticle(articleList[currentArticleIndex]);

      const prevSlug =
        currentArticleIndex === 0
          ? null
          : `${themePaths.blog}${
              articleList[currentArticleIndex - 1]?.blog_category?.slug
            }/${articleList[currentArticleIndex - 1]?.slug}`;
      const nextSlug =
        currentArticleIndex === articleList.length - 1
          ? null
          : `${themePaths.blog}${
              articleList[currentArticleIndex + 1]?.blog_category?.slug
            }/${articleList[currentArticleIndex + 1]?.slug}`;

      setNavSlugs({ prevSlug, nextSlug });
    } else {
      const trendingArticleIndex = trendingArticles.current.findIndex(
        (article) => {
          return article.slug === articleSlug;
        }
      );
      if (trendingArticleIndex !== -1) {
        updateSortedArticles(trendingArticles.current);
      } else {
        setError(true);
      }
    }
  }, [
    sortedArticles,
    articleSlug,
    articleData,
    trendingArticles,
    updateSortedArticles,
  ]);

  const articleDate = new Date(currentArticle?.createdAt).toLocaleString(
    "en-us",
    {
      month: "long",
      day: "numeric",
      year: "numeric",
    }
  );
  const handleMoreInCategory = () => {
    updateCategoryFilter(currentArticle.blog_category.name);
    navigate(`/${themePaths.blog}${themePageUrlPaths.allBlogs}`);
  };
  const handleMoreArticles = () => {
    updateCategoryFilter("All");
    navigate(`/${themePaths.blog}${themePageUrlPaths.allBlogs}`);
  };

  return (
    <Layout>
      <Helmet>
        <meta name="title" content={currentArticle?.title} />
        <meta name="description" content={currentArticle?.description} />

        {/* OpenGraph tags */}
        <meta
          property="og:title"
          content={`${process.env.GATSBY_SITE_NAME} | ${currentArticle?.title}`}
        />
        <meta
          property="og:description"
          content={`${process.env.GATSBY_SITE_NAME} | ${currentArticle?.description}`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://artefy-strapi-boilerplate-4mdevelopment.s3.ap-southeast-2.amazonaws.com/metal_plus_97826b79e2.png"
        />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="50" />

        {/* Twitter Card tags */}
        <meta
          name="twitter:card"
          content={`${process.env.GATSBY_SITE_NAME} | ${currentArticle?.title}`}
        />
        <meta
          name="twitter:creator"
          content={`${process.env.GATSBY_TWITTER_HANDLE}`}
        />
        <meta
          name="twitter:title"
          content={`${process.env.GATSBY_SITE_NAME} | ${currentArticle?.title}`}
        />
        <meta
          name="twitter:description"
          content={`${process.env.GATSBY_SITE_NAME} | ${currentArticle?.description}`}
        />
        <title>{`${process.env.GATSBY_SITE_NAME} | ${currentArticle?.title}`}</title>
      </Helmet>

      <Container maxWidth="lg" className="pt-[122px]">
        {error ? (
          <p>no article found</p>
        ) : (
          <>
            <h2 className="text-fadedText text-lg font-medium">
              {currentArticle?.blog_category?.name}
            </h2>
            <h1 className="text-red font-extrabold text-4xl mb-4 mt-6">
              {currentArticle?.title}
            </h1>

            <div>
              <div className="w-full">
                <img
                  className="w-full"
                  src={`${process.env.GATSBY_ASSET_URL}${currentArticle?.featured_image?.url}`}
                  alt={currentArticle?.title}
                />
              </div>
            </div>
            <div>
              <AuthorBar
                createdAt={articleDate}
                imageUrl={currentArticle?.author?.image.url}
                authorUsername={currentArticle?.author?.username}
                slug={`${articleSlug}`}
              />
            </div>

            <div className="flex flex-col justify-center items-center">
              <div className="flex flex-col justify-center items-center mt-8 text-sm  md:w-4/5 ">
                {blocks?.length === 0 ? (
                  <ParsedHTML html={currentArticle?.article?.data?.article} />
                ) : (
                  blocks?.map((blk) => {
                    return (
                      <div className="mb-4">
                        <ParsedHTML key={blk.id} html={blk.richtext} />
                      </div>
                    );
                  })
                )}
              </div>
            </div>

            <div className="text-red text-lg font-bold flex items-end justify-between border-b border-darkGray py-2 mt-12 flex flex-col items-center sm:flex-row">
              <button
                className="cursor-pointer text-red text-lg font-bold"
                onClick={handleMoreInCategory}
              >
                More in Category
              </button>
              <div className="flex gap-x-8">
                {navSlugs.prevSlug ? (
                  <Link to={`/${navSlugs.prevSlug}`}>
                    <ArrowBackIcon /> Previous Article
                  </Link>
                ) : null}

                {navSlugs.nextSlug ? (
                  <Link to={`/${navSlugs.nextSlug}`}>
                    Next Article <ArrowForwardIcon />
                  </Link>
                ) : null}
              </div>
            </div>
          </>
        )}
      </Container>

      <Container maxWidth="lg" className="mt-24 mb-24">
        <TrendingArticles
          blog_articles={trendingArticles.current}
          darkmode={false}
          loadMoreArticles={handleMoreArticles}
        />
      </Container>

      <Container maxWidth="lg" className="mt-24 mb-24">
        <PopularCategories blog_categories={strapiCategories} />
      </Container>
    </Layout>
  );
};

export default Article;

/*
blocks {
  richtext {
    data {
      richtext
    }
  }
}
*/

export const query = graphql`
  query {
    allStrapiBlogArticle(
      filter: { publishedAt: { ne: null } }
      sort: { order: DESC, fields: updatedAt }
    ) {
      nodes {
        id
        slug
        title
        description
        author {
          username
          image {
            url
          }
        }
        blog_category {
          slug
          name
        }
        featured_image {
          url
        }
        article {
          data {
            article
          }
        }
        createdAt
      }
    }
    allStrapiCategory(
      filter: { blog_articles: { elemMatch: { publishedAt: { ne: "null" } } } }
      limit: 4
    ) {
      nodes {
        id
        featured_image {
          url
        }
        name
        slug
      }
    }
  }
`;
