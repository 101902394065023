
//All defaults set here will be over-written by consts from custom-paths.js
//shown in browser
const themePaths = {
  blog: 'news/',
  marketplace: 'marketplace/',
  nfts: 'nfts/',
  collections: 'collections/',
};

//shown in browser
const themePageUrlPaths = {
  blog: 'blog',
  allBlogs: 'all-blogs',
  article: 'article'
};

//filenames
const themePages = {
  blog: 'blog',
  allBlogs: 'allBlogs',
  article: 'article',
  product: 'product',
  collections: 'nftcollections',
  collection: 'nftcollection',
  tiercollections: 'tiercollections'
};

//For filesystem references only
const themeSystemPaths = {
  blog: 'pages/blog/',
  marketplace: 'pages/marketplace/'
};


module.exports = {
  themePaths: themePaths,
  themePages: themePages,
  themeSystemPaths: themeSystemPaths,
  themePageUrlPaths: themePageUrlPaths
};
