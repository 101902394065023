import React from "react";
import CategoryCard from "../molecules/CategoryCard";

const PopularCategories = (props) => {
  return (
    <div>
      <h2 className="uppercase mb-12 text-3xl font-bold text-center">
        Selected <span className="text-primary">Categories</span>
      </h2>
      <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-4 lg:gap-x-8">
        {props.blog_categories.map((item) => (
          <li key={item.id}>
            <CategoryCard category={item} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PopularCategories;
