import React from "react";

const AuthorCard = (props) => {
  return (
    <div className="flex gap-x-2 items-center">
      <img
        className="inline-block h-10 w-10 rounded-full"
        src={`${process.env.GATSBY_ASSET_URL}${props.imageUrl}`}
        alt={props.authorUsername}
      />
      <div className="flex flex-col">
        <div className="text-fadedText font-medium">{props.createdAt}</div>
        <div>
          <span className="text-fadedText">By: </span>
          {props.darkmode ? (
            <span className="text-secondary font-bold">
              {props.authorUsername}
            </span>
          ) : (
            <span className="text-red font-bold">{props.authorUsername}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthorCard;
