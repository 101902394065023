import React, { useEffect, useState } from "react";
import AuthorCard from "./AuthorCard";
import SocialIcons from "./SocialIcons";

const AuthorBar = ({ createdAt, imageUrl, authorUsername, slug = null }) => {
  const [windowLocation, setWindowLocation] = useState("");

  useEffect(() => {
    setWindowLocation(window.location.href);
  }, []);

  const redirectUrlFull = windowLocation;

  return (
    <div className="flex items-end justify-between border-b-2 border-red py-2">
      <AuthorCard
        createdAt={createdAt}
        imageUrl={imageUrl}
        authorUsername={authorUsername}
      />

      {slug ? (
        <div className="flex items-center gap-x-2">
          <span className="text-grayText font-medium text-lg">Share: </span>
          <SocialIcons shareUrl={`${redirectUrlFull}`} />
        </div>
      ) : null}
    </div>
  );
};

export default AuthorBar;
