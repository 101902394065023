import React from "react";
import AuthorCard from "./AuthorCard";
import { graphql } from "gatsby";
import { Link } from "gatsby";

const { themePaths } = require("../../../paths.js");

export const query = graphql`
  fragment ArticleData on STRAPI_BLOG_ARTICLE {
    id
    createdAt
    title
    blog_category {
      name
      slug
    }
    article {
      data {
        article
      }
    }
    author {
      image {
        url
      }
      username
    }
    featured_image {
      url
    }
  }
`;
const ArticleCard = ({ article, darkmode }) => {
  const articleDate = new Date(article?.createdAt).toLocaleString("en-us", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  return (
    <>
      <Link
        className="text-primary"
        to={`/${themePaths.blog}${article?.blog_category?.slug}/${article?.slug}`}
      >
        <div className="mx-auto max-w-[420px] min-w-[200px] border-b border-red bg-bgPrimary bg-opacity-5 bg-bgTertiary">
          <div
            className="w-full h-56 bg-center bg-cover"
            style={{
              backgroundImage: `url(${process.env.GATSBY_ASSET_URL}${article?.featured_image?.url})`,
            }}
          ></div>
          <div className="p-4">
            <div className="flex flex-col gap-y-4">
              <span className="text-secondary">
                {article?.blog_category?.name}
              </span>
              <span
                className="text-lg font-medium text-primary"
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  height: "32px",
                }}
              >
                {article?.title}
              </span>
              <p
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: "3",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  height: "72px",
                }}
                className="text-grayText"
              >
                {article?.description}
              </p>
              <AuthorCard
                createdAt={articleDate}
                imageUrl={article?.author?.image.url}
                authorUsername={article?.author?.username}
                darkmode={darkmode}
              />
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default ArticleCard;
