import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Link } from "gatsby";

const SocialIcons = ({ shareUrl }) => {
  //Instagram removed due to no share functionality from websites.
  const facebookShare = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}%2F&amp;src=sdkpreparse`;
  const twitterShare = `http://twitter.com/share?url=${shareUrl}`;
  return (
    <div className="flex items-center justify-center gap-x-1">
      <Link
        to={ facebookShare }
        target="_blank"
      >
        <FacebookIcon />
      </Link>
      <Link
        to={ twitterShare }
        target="_blank"
      >
        <TwitterIcon />
      </Link>
    </div>
  );
};
export default SocialIcons;
