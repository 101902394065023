import React from "react";
// import DOMPurify from "dompurify";
// import parse from "html-react-parser";
// import * as styles from "./parsedHTML.module.css";

const ParsedHTML = ({ html }) => {

  if(html){
    html = html.replaceAll("<h1>","<h2 class='text-primary text-3xl font-bold mb-5'>");
    html = html.replaceAll("<h2>","<h2 class='text-primary text-2xl font-bold mb-5'>");
    html = html.replaceAll("<h3>","<h3 class='text-primary text-xl font-bold' mb-5>");
    html = html.replaceAll("<h4>","<h4 class='text-primary text-l font-bold' mb-5>");

    html = html.replaceAll("<table>","<table class='mt-5 mb-5' style='margin-left: -9px; border-spacing: 18px; border-collapse: separate;'>");

    html = html.replaceAll("<a","<a target='_blank' class='text-primary underline italic' ");

    html = html.replaceAll("<br","<br class='mb-5 mt-5' ");

    html = html.replaceAll("<blockquote>","<blockquote class='border-l-4  border-neutral-500 quote mb-8 mt-8 px-4 text-sm text-center text-lg'> ");

    html = html.replaceAll("<img","<img class='w-full' ");

    html = html.replaceAll("<td","<td class='align-top' ");

    html = html.replaceAll("<p","<p class='leading-5 mb-5' ");

    html = html.replaceAll("<ol","<ol class='mt-5 mb-5 ml-10' style='list-style-type: upper-roman;'");

    html = html.replaceAll("<ul","<ul class='mt-5 mb-5 ml-10' style='list-style-type: circle;'");

    html = html.replaceAll("<b>","<b class='font-bold'>");
  }


  return (
    <div
      dangerouslySetInnerHTML={{__html: html}}
    />
  )
};

export default ParsedHTML;
